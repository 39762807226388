import React from "react";

import Layout from "~components/base/layout";
import SEO from "~components/base/seo";
import "~styles/pages/services/_product-photos.scss";
import Products from "~components/products/products";
import TypeAnimation from "react-type-animation";

const ProductPhotosPage = () => (
  <Layout>
    <SEO title="Product Photos" />
    <section className="page-heading">
      <div className="container">
        <TypeAnimation
          cursor={false}
          sequence={["Product Photos", 500]}
          className={"title"}
          wrapper="h1"
        />
      </div>
    </section>
    <section className="section-product-items">
      <div className="container">
        <Products>
          <div
            title="1 - 5 photos of 1 product"
            subtitle="$100 per final image"
            id="photo-1"
          >
            <p>
              Algorithm-optimized product photography that leverages what Amazon
              uses for their best-selling products. We manage and run so many
              ASC, VC and BC programs for multiple brands that we have stolen a
              trick or two that we really shouldn’t be sharing with everyone.
            </p>
          </div>
          <div
            title="5+ images for 1 product"
            subtitle="$75 per final image"
            id="photo-3"
          >
            <p>
              Want a lot of options? Choose this package and we will take 5-10
              (your choice) images of each product you send us, to ensure you
              have every angle and detail covered.
            </p>
          </div>
          <div
            title="Add-On: Consulting package"
            subtitle="$300 per project"
            id="photo-2"
          >
            <p>
              Want something extra special? Setup a consultation with our
              Product Photography team and let us help your vision come to life.
              Consultation includes moodboards and recommendations for angles,
              lighting, styling and 360° video to leverage your product
              photography in the most impactful way.
            </p>
          </div>
        </Products>
      </div>
    </section>
    <section className="section-calculator">
      <div className="container">
        <h3>
          Projects with 50+ products are quoted on a case-by-case basis, please
          get in touch with us if this is you!
        </h3>
      </div>
    </section>
  </Layout>
);

export default ProductPhotosPage;
