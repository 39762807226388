import React, { useState, useEffect, useRef } from "react";
import { Link } from "gatsby";
import Accordion from "../../components/accordion/accordion";
import "./_products.scss";

const Desc = ({ children }) => {
  const [height, setHeight] = useState(0);
  const [visible, setVisible] = useState(false);
  const elementRef = useRef(null);

  useEffect(() => {
    setHeight(elementRef.current.clientHeight);
  }, []);

  const onClick = () => {
    setVisible(!visible);
  };

  return (
    <div className="product-desc" ref={elementRef}>
      {height > 250 && window.innerWidth < 1700 ? (
        <>
          <div className={visible ? "" : "product-desc-limit"}>{children}</div>
          <button onClick={onClick} className="btn-toggle">
            {visible ? "Show less" : "Show more"}
          </button>
        </>
      ) : (
        <>{children}</>
      )}
    </div>
  );
};

const Products = ({ children, type }) => {
  var wrapperClass =
    type === "slim"
      ? "product-component-slim product-accordion"
      : type === "outline"
      ? "product-component-outline product-accordion"
      : "product-component product-accordion";
  const childes = React.Children.toArray(children);
  return (
    <div className={wrapperClass}>
      <Accordion loadmore={false}>
        {childes.map((child, index) => (
          <div
            className="product-item"
            key={index}
            label={child.props.title}
            subtitle={child.props.subtitle}
            extrainfo={child.props.extrainfo}
            sectionid={child.props.sectionid}
          >
            <div className="bottom">
              <Desc>{child.props.children}</Desc>
              <Link
                to="/checkout"
                className="btn btn-dark"
                state={{
                  id: child.props.id,
                }}
              >
                LEARN MORE
              </Link>
            </div>
          </div>
        ))}
      </Accordion>
    </div>
  );
};

export default Products;
